// Styles for Projects 

.pt-page-2 {
  background-color: $color-background-dark;
}

#SideBar {
	display: block;
	width: 10%;
	height: 100%;
	float: left;

  @include mq(all-mobile) {
    display: block;
    height: 48px;
    width: 48px;
    border-radius: 30px;
    position: absolute;
    top: 24px;
    left: 20px;
    z-index: 1000;
    opacity: 65%;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  }

	.pt-trigger {
		@include btn-styling(btn-back);
	}
}


#ProjectsWrapper {
	display: block;
	width: 90%;
	height: 100%;
	overflow-y:scroll;
  @include mq(all-mobile) {
    width: 100%;
  }
}

.projects {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	.project-content {
		display: block;
		position: absolute;
		top:25%;
		left:8%;
		width: 50%; 
		z-index: 100;

    // Animate
    opacity: 0;
    transform: translateY(-20px);
    // Transition
    transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;

    &.drop {
      opacity: 1; 
      transform: translateY(0px);
    }

    @include mq(all-mobile) {
      top: 20vh;
      width: 90%;
      padding: 5%;
      left: 0px;
      background: rgba(0,0,0,0.1);
    }

		span {
			text-transform: uppercase;
			font-size: $font-size-16;
      font-family: $font-link;
      font-weight: 400; 
			line-height: 3rem;
			opacity: 0.6;
			padding-left: 10px;

			@include mq(not-desktop) {
				font-size: $font-size-14;
				line-height: 2.8rem;
			}
		}
    b {
      color: #fff;
      padding-left:8px;
    }

		h2 {
			margin-top: 30px;
			color: #fff;
      font-size: 5rem;

      @include mq(not-desktop) {
        margin-top: 24px;
        font-size: 4.8rem;
      }
      @include mq(all-mobile) {
        font-size: 3.8rem;
        margin-top: 8px;
      }
		}
		p {
			margin-top: 20px;
			font-size:$font-size-16;
			line-height: 2.2rem;
			font-weight: $font-weight-400;
			color: $color-background-light;
			width: 80%;
      @include mq(not-desktop) {
        font-size:$font-size-16;
        margin-top: 18px;
        width: 100%;
        line-height: 2rem;
      }
      @include mq(all-mobile) {
        font-size:$font-size-14;
        margin-top: 12px;
      }
		}
		a {
			text-decoration: none;
			font-weight: 600;
		}
		.button {
			@include btn-styling(btn-project);

      @include mq(not-desktop) {
        margin-top: 24px;
      }
      @include mq(all-mobile) {
        margin-top: 20px;
      }
		}
	}

	.project-visual {
    display: block;
    position: absolute;
    right: 0px; 
    bottom: -800px;
    height: 70%;
    width: 50%;
    z-index: 10;
    opacity: 0.2;
    background-position: top-left;
    background-size: auto 100%;
    // Transition
    transition: all .6s ease-in-out, transform .1s linear;
    -moz-transition: all .6s ease-in-out, transform .1s linear;
    -webkit-transition: all .6s ease-in-out, transform .1s linear;

    &.active {
      bottom:0;
      opacity: 0.8;
    }

    @include mq(lg-desktop) {
      height: 70vh;
    }
    @include mq(not-desktop) {
      width: 35%;
    }
    @include mq(all-mobile) {
      height: 60%;
      width: 80%;
      &.active {
        opacity: 0.4;
      }
    }

    // Video contained in visual
    &_vid {
      display: block;
      position: absolute;
      width: auto;
      height: 90%;
      left: 15px; 
      top: 800px;
      z-index: 10;
      opacity: 0.2;
      // Transition
      transition: all .6s ease-in-out, transform .1s linear;
      -moz-transition: all .6s ease-in-out, transform .1s linear;
      -webkit-transition: all .6s ease-in-out, transform .1s linear;

      &.active {
        top:66px;
        opacity: 0.9;
      }
    }

	}
}

.projects-1 {
	@include project-color($color-c1-p1, $color-c2-p1, $color-c3-p1);

	.project-visual {
		background: url(../../img/phixl.png) no-repeat;
	}
}

.projects-2 {
	@include project-color($color-c1-p2, $color-c2-p2, $color-c3-p2);

	.project-visual {
		background: url(../../img/2degrees.png) no-repeat;
	}
}


.projects-3 {
	@include project-color($color-c1-p3, $color-c2-p3, $color-c3-p3);

	.project-visual {
		background: url(../../img/unitec.png) no-repeat;
	}
}

// .projects-4 {
// 	@include project-color($color-c1-p4, $color-c2-p4, $color-c3-p4);

// 	.project-visual {
// 		background: url(../img/btx.png) no-repeat;
// 	}
// }

.projects-5 {
	@include project-color($color-c1-p5, $color-c3-p5, $color-c2-p5);

  .project-content {

    span {
      border-left: 1px solid $color-c3-p5 !important;
      color: $color-c3-p5 !important;
    }

    h2, p, b {
      color: $color-c4-p5 !important;
    }
  }

	.project-visual {
		background: url(../../img/phl-admin.png) no-repeat;
	}
}

.projects-6 {
  @include project-color($color-c1-p6, $color-c2-p6, $color-c3-p6);

  .project-visual {
    background: url(../../img/roomorama.png) no-repeat;
  }
}

.projects-7 {
  @include project-color($color-c1-p7, $color-c2-p7, $color-c3-p7);

  .project-visual {
    background: url(../../img/msteams.png) no-repeat;
  }
}

.projects-8 {
  @include project-color($color-c1-p8, $color-c2-p8, $color-c3-p8);

  .project-visual {
    background: url(../../img/phl-chat-bg.png) no-repeat;
  }
}

.projects-9 {
  @include project-color($color-c1-p9, $color-c2-p9, $color-c3-p9);

  .project-content {

    // span {
    //   border-left: 1px solid $color-c3-p5 !important;
    //   color: $color-c3-p5 !important;
    // }

    h2, p, b {
      color: $color-c4-p5 !important;
    }
  }

  .project-visual {
    background: url(../../img/jmrvisas_2.png) no-repeat;
  }
}

.projects-1, .projects-6 {

  .project-content {
    margin-top: 3vh;
  }

  .button {
    display: none !important;
  }
}