// Styles for Contact

#Contact {
	position: fixed;
	top: -1200px; left: 0px; right: 0px;
	background-size: cover;
  background-position: top left;
  background-repeat: repeat;
 	background-color: rgba(20,20,20,0.96);
  width: 100%;
  height: 100%;
	margin: auto;
	z-index: 200;

	.contact-container {
		position: relative;
	  display: block;
	  width: 960px;
	  height: 100%;
	  overflow: hidden;
	  margin: auto;

	  @include mq(all-tablet) {
	  	width: 100%;

	  	.close-btn {
	  		right: 30px
	  	}

	  	.contact-block {
	  		top: 20vh;
	  		width: 60vw;
	  		padding-left: 5%;

	  		h1 {
			  	font-size: 5rem;

			  	b {
			  		font-size: 5.6rem;
			  	}
			  }
	  	}
	  }

	  @include mq(all-mobile) {
	  	width: 100%;

	  	.close-btn {
	  		right: 30px;
	  		top: 30px;
	  		width: 30px; height: 30px;
	  	}

	  	.contact-block {
	  		top: 10vh;
	  		width: 90%;
	  		padding: 5%;
	  		background: rgba(20,20,20,0.7);
	  		min-height: 500px;

	  		h1 {
			  	font-size: 3.6rem;

			  	b {
			  		font-size: 4.2rem;
			  		padding: 5px;
			  	}
			  }
	  	}

	  	img.about_photo {
	  		height: 75vh;
	  		width: auto;
	  		opacity: 0.75;
	  	}

	  }
	}

	.close-btn {
		@include btn-styling(btn-close);
	}

  &.visible {
    top:0px;

    img.about_photo {
    	opacity: 100%;
  		bottom: 0px;
    }
  }

  // Block
  .contact-block {

  position: relative;
  top: 25vh;
  z-index: 100;
  width: 40vw;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  justify-content: space-between;

  	// Intro
	  h1 {
	  	color: $color-font-title-light;
	  	line-height: 100%;
	  	font-size: 5.4rem;

	  	b {
	  		font-size: 6rem;
	  		padding: 8px;
	  		background-color: #E7FF84;
	  		color: rgba(20,20,20,1);
	  	}
	  }
	  .sep {
	  	width: 120px;
	  	height: 2px;
	  	display: block;
	  	background-color: #fff;
	  }
	  .contact-intro {
	  	color: $color-font-copy-light;
	  	margin-top: 8px;
	  	letter-spacing: -.1rem;

	  	li {
	  		line-height: 140%;
	  		font-size: 1.4rem;
	  		color: #fff;

	  		b {
	  			color: #E7FF84;
	  		}

	  		i {
	  			font-style: italic;
	  			color: #D39A84;
	  		}
	  	}
	  }

	  // Social
	  .social-links {

	  	display: flex;
  		flex-direction: row;

	  	.link {
	  		width: 20px;
	  		height: 20px;
	  		display: block;
	  		opacity: 0.7;
	  		margin-right: 8px;

	  		&:hover {
	  			opacity: 1;
	  		}

	  		&_linkedin {
	  			background: url(../../img/01_linkedin.png) no-repeat;
	  			background-size:contain;
	  		}
	  		&_github {
	  			background: url(../../img/02_github.png) no-repeat;
	  			background-size:contain;
	  		}
	  		&_dribbble {
	  			background: url(../../img/03_dribbble.png) no-repeat;
	  			background-size:contain;
	  		}
	  		&_instagram {
	  			background: url(../../img/04_instagram.png) no-repeat;
	  			background-size:contain;
	  		}
	  	}

	  }

	  // CTAS
	  .ctas {

	  	.contact-btn { 
	  		@include btn-styling(btn-project); 

	  		margin-top: 0px;
	  	}
				
			.contact-btn-1 {
				background-color: rgba(255,255,255,0.7);
				color: rgba(20,20,20,1);
				margin-right: 20px;

				&:hover {
					background-color: #fff;
				}
			}
			.contact-btn-2 {
				background: none;
				border: 2px solid rgba(231,255,132,0.7);
				color: rgba(231,255,132,0.7);
				padding: 13px;

				&:hover {
					border: 2px solid #E7FF84;
					color: #E7FF84;
				}
			}
	  }
	  

  }


  // Photo
  img.about_photo {
  	position: absolute;
  	right: 0px; bottom: 0px;
  	height: 660px;
  	width: auto;
  	opacity: 20%;
  	bottom: -800px;

  	// Transition
    transition: bottom .3s ease-in-out, opacity .9s linear;
    -moz-transition: bottom .3s ease-in-out, opacity .9s linear;
    -webkit-transition: bottom .3s ease-in-out, opacity .9s linear;
  }

}

.contactOverlay {
	position: absolute;
	top: 0px; left: 0px;
	background-color: $color-background-dark;
	opacity: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	visibility: hidden;
} 