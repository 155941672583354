// Blocks Elements
//
// Still under refactoring

body 
{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.pt-page 
{
  height:100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  // Transition
  transition: transform .3s ease-in-out;
  -moz-transition: transform .3s ease-in-out;
  -webkit-transition: transform .3s ease-in-out;
}

// Home Setting
.pt-page-1 {
  &.homeShown {
    transform:translateX(0px);
  }
  &.projectShown {
    transform:translateX(-100%);
  }
}
// Project Setting
.pt-page-2 {
  &.homeShown {
    transform:translateX(100%);
  }
  &.projectShown {
    transform:translateX(0px);
  }
}

.pt-page-current 
{
  visibility: visible;
  z-index: 1;
}

.pt-wrapper 
{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.InsideWrapper
{
  position: relative;
  display: block;
  width: 960px;
  height: 100%;
  overflow: hidden;
  margin: auto;

  @include mq(all-mobile) {
    width:100%;
  }
}