// Styles for Header

#TopBar {
	position: fixed;
	top: 50px; 
	left:10%; 
	right:10%;
	// display: flex;
  	// justify-content: space-between;
  	// align-items: center;
	z-index: 10;
	height: 60px;
	transform: scale(1, 1);
	// Transition
	transition: all .2s ease-in-out, transform .1s linear;
	-moz-transition: all .2s ease-in-out, transform .1s linear;
	-webkit-transition: all .2s ease-in-out, transform .1s linear;

	&.shrink {
		transform: scale(0.94, 0.9);
		top: 80px;
	}

	@include mq(all-mobile) {
		top: 20px;
		left: 20px;
		right: 20px;
	}

	&.project {
		width: 60%;
  		left: inherit !important;

  		.nav-btn {
  			text-shadow: 1px 0px 10px rgba(0,0,0,0.25);
  		}
	}

	.logo {
		display: block;
 		width: 60px;
 		height: 60px;
 		background-size: 60px 60px;
 		border:none;
 		float:left;

 		// Dark theme indicator
 		&:after {
			display: inline-block;
			position: absolute;
			left: 70px;
			top: 13px;
			max-width: 130px;
			font-size: 11px;
			font-weight: 500;
			font-family: $font-link;
			line-height: 1.8rem;
			cursor: default;

			@include css-animate(the-ghost,10s,infinite);

			@include mq(all-mobile) {
				left: 5px;
				top: 70px;
			}
 		}
	}
	.SubNav {
		font-size: 1.4rem;
		text-transform: uppercase;
		float: right;
		li {
			display: inline-block;
		}
		li a {
			display: block;
			text-decoration: none;
			line-height: 6.0rem;
			font-weight: 600;
			letter-spacing: 1px;
			margin-left: 30px;
			transition: color 0.6s ease;
			color: $color-font-title-dark;

			&:hover {
				cursor: pointer;
			}

			@include mq(all-mobile) {
				background-color: rgba(0,0,0,0.15);
				font-weight: $font-weight-600;
				font-size: 1.4rem;
				padding: 12px 10px;
				line-height: inherit;
				border-radius: 3px;
				margin-left:10px;
				color: lighten($color-font-title-dark,15%);
				margin-top: 10px;
				// Transition
				transition: all .2s ease-in-out, transform .1s linear;
			  -moz-transition: all .2s ease-in-out, transform .1s linear;
			  -webkit-transition: all .2s ease-in-out, transform .1s linear;

				&:hover {
					background-color: rgba(0,0,0,0.20);
					color: lighten($color-font-title-dark,10%);
				}
			}
		}
	}
}
