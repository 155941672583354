// Variables used for the website

@use 'sass:color';

// 01 - Colours

	// 01-1 - Background Colours

	$color-background-light: #f8f6f5;
	$color-background-dark: #262427;
	$color-background-sep-light: rgba(0,0,0,0.2);
	$color-background-sep-dark: rgba(255,255,255,0.2);

	// 01-2 - Font Colours

	$color-font-title-dark: #262427;
	$color-font-copy-dark: #4d4850;
	$color-font-title-light: #f8f6f5;
	$color-font-copy-light: #ccc;
	$color-font-span: #857e88;

	// 01-3 - Projects Colours

	$color-c1-p1: #1b3e3f;
	$color-c2-p1: #2d5759;
	$color-c3-p1: #428689;

	$color-c1-p2: #085a94;
	$color-c2-p2: #1970ae;
	$color-c3-p2: #56a1cc;

	$color-c1-p3: #cf330d;
	$color-c2-p3: #df5706;
	$color-c3-p3: #eb833f;

	// $color-c1-p4: #f5a109;
	// $color-c2-p4: #ffb400;
	// $color-c3-p4: #272120;

	$color-c1-p5: #fff;
	$color-c2-p5: #eae7e4;
	$color-c3-p5: #f07424;
	$color-c4-p5: #3b3e4e;

	// $color-c1-p6: darken(#E14536,30%);
	$color-c1-p6: color.scale(#E14536, $lightness: -50%);
	// $color-c2-p6: darken(#E14536,20%);
	$color-c2-p6: color.scale(#E14536, $lightness: -40%);
	$color-c3-p6: #E14536;

	$color-c1-p7: #fff;
	$color-c2-p7: #5161bb;
	$color-c3-p7: #8f9ced;

	$color-c1-p8: #f07424;
	// $color-c2-p8: lighten(#f07424,10%);
	$color-c2-p8: color.scale(#f07424, $lightness: +10%);
	$color-c3-p8: #3b3e4e;

	$color-c1-p9: #5359E9;
	$color-c2-p9: #6A70FF;
	$color-c3-p9: #c8c8d5;


	// 01-4 - Competences Colours

	$color-userinterface: #6faa94;
	$color-branding: #8e77a7;
	$color-branding-hover: #795f96;
	$color-illustration: #d8836c;
	$color-illustration-hover: #c56a51;


// 02 - Typography

	// 01 - Fonts

	$font-title:		'Poppins', sans-serif;
	$font-title_new:	'Fira Sans', sans-serif;
	$font-copy:    		'Space Grotesk', sans-serif;
	$font-copy_new:		'Fira Mono', monospace;
	$font-link:			'IBM Plex Mono', monospace;

	// 02 - Size

  	$font-size-14:      	1.4rem;
	$font-size-16: 			1.6rem;
	$font-size-18:			1.8rem;
	$font-size-20:			2.0rem;
	$font-size-24:			2.4rem;
	$font-size-h1:			6.0rem;	
	$font-size-h2:			5.4rem;

	// 03 - Weight

	$font-weight-200:				200;
	$font-weight-400:				400;
	$font-weight-500:				500;
	$font-weight-600:				600;
	$font-weight-800:				800;

	// 04 - Letter Spacing

	$font-spacing-title:	-0.02em;


// 03 - Media Queries

	//Media Queries Variables 

	$mobilePort: "(max-width: 545px)";
	$mobileLand: "(max-width: 767px)";
	$tablet: "(max-width: 1200px)";
	$desktop: "(min-width: 1201px)";	

	// ==> This is being refactored 

	// Extra small screen / phone
              
	$screen-xs-min:              480px;
	$screen-phone:               $screen-xs-min;

	// Small screen / tablet

	$screen-sm-min:              768px;
	$screen-tablet:              $screen-sm-min;

	// Medium screen / desktop

	$screen-md-min:              992px;
	$screen-desktop:             $screen-md-min;

	// Large screen / wide desktop

	$screen-lg-min:              1200px;
	$screen-lg-desktop:          $screen-lg-min;

	// So media queries don't overlap when required, provide a maximum
	$screen-xxs-max:			 ($screen-xs-min - 1);
	$screen-xs-max:              ($screen-sm-min - 1);
	$screen-sm-max:              ($screen-md-min - 1);
	$screen-md-max:              ($screen-lg-min - 1);