// Styles for Home Page

@include theme-switcher;


.noise-bg {
	pointer-events: none;
	opacity: 0.3; // control the noise opacity
	filter: grayscale(100%); // convert to grayscale colors
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		filter: url(#noise-bg-fx);
	}
}

.introduction {
	display: block;
	width: auto;
	position:relative;
	margin: 36vh 20vh;

	@include mq(all-mobile) {
		width:85%;
		margin: 35vh auto;
		padding: 0 30px;
	}

	h1 {
		margin-bottom: 20px;
	}

	p {
		font-size:1.8rem;
		line-height: 3.2rem;
		display: block;
		position: relative;

		@include mq(all-mobile) {
			line-height: 2.8rem;
			font-size: 1.6rem;
			padding: 0 20px 0 0;
		}

		// span.skills-slide {
		// 	font-size: $font-size-24;
		// 	line-height: 4.0rem;
		// 	font-weight: 600;
		// 	display: inline;
		// 	position: relative;
		// 	opacity: 0%;
		// 	top: -20px;
		// 	// animation: let-it-drop infinite 2.5s;
		// 	@include css-animate(let-it-drop,3.5s,infinite);

		// 	&:before {
		// 		content: '';
		// 		// animation: animate-text infinite 10s;
		// 		@include css-animate(animate-text,14s,infinite);
		// 	}
		// }
	}

	.pt-trigger
	{
		@include btn-styling(btn-home);
	}
}

