// ToolTip

.tooltip 
{
	display: inline;
	position: relative;
	z-index: 999;

	@include mq(all-mobile) {
		display: inline-block;
	}
}

.tooltip-item 
{
	padding: 4px 8px;
	-webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px;
	font-size: $font-size-18;
	text-transform: uppercase;
	cursor: pointer;
	font-weight: 600;
	opacity: 0.8;
}

/* Tooltip */

.tooltip-content {
	position: absolute;
	z-index: 1000;
	width: 310px;
	left: 50%;
	margin: 0 0 20px -150px;
	bottom: 100%;
	background: $color-background-dark;
	opacity: 0;
	cursor: default;
	pointer-events: none;
	border-radius:2px;
}


.tooltip-effect-4 .tooltip-content {
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transform: scale3d(0.7,0.3,1);
	transform: scale3d(0.7,0.3,1);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
}

.tooltip:hover .tooltip-content {
	pointer-events: auto;
	opacity: 1;
	-webkit-transform: translate3d(0,0,0) rotate3d(0,0,0,0);
	transform: translate3d(0,0,0) rotate3d(0,0,0,0);
}



/* Arrow */

.tooltip-content::after {
	content: '';
	top: 100%;
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: transparent;
	border-top-color: #262427;
	border-width: 10px;
	margin-left: -10px;
}

/* Tooltip content*/

.tooltip-content img {
	position: relative;
	height: 170px;
	display: block;
	float: left;
	margin-right: 10px;
	background-color: #013ba6;
  padding: 0px 10px;
}

.tooltip-text {
	text-align: left;
	font-size: $font-size-14;
	line-height: 2.0rem;
	display: block;
	padding: 10px;
	color: #fff;
}

.tooltip-smallText
{
	font-size: 1.2rem;
	line-height: $font-size-18;
	color: #999;
	display: block;
	margin-top: 15px;
}